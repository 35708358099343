import Content from "./Content";
import Header from "./Header";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function BlockHalus() {
  const location = useLocation();
  const [content, setContent] = useState<string>("halus");

  useEffect(() => {
    if (location.pathname.includes("blok-halus")) {
      setContent("halus");
    } else if (location.pathname.includes("blok-kasar")) {
      setContent("kasar");
    } else if (location.pathname.includes("jalan-dasar")) {
      setContent("jalan");
    }
  }, [location.pathname]);
  return (
    <>
      <Header content={content} />
      <Content />
    </>
  );
}

export default BlockHalus;
