import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Language = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    let page: string = location.pathname;

    if (lang === "en") {
      navigate(page.slice(0, -3) + "/en");
      handleTrans("en");
    } else {
      navigate(page.slice(0, -3) + "/id");
      handleTrans("id");
    }
    // navigate("/session-timed-out");
  };

  const handleTrans = (code: string) => {
    i18n.changeLanguage(code);
  };
  return (
    <div>
      <div className="flex justify-between mb-2 px-3">
        <div className="flex md:flex-row gap-2 md:text-base text-xs w-full ">
          <h1 className="mr-1 text-base font-bold">Language : </h1>
          <a
            onClick={() => {
              changeLanguage("id");
              window.scrollTo({
                top: 0,
                behavior: "smooth", // Add smooth scrolling behavior
              });
            }}
            className="cursor-pointer flex items-center text-black text-base underline"
          >
            Indonesia (ID)
          </a>
          <a
            onClick={() => {
              changeLanguage("en");
              window.scrollTo({
                top: 0,
                behavior: "smooth", // Add smooth scrolling behavior
              });
            }}
            className="cursor-pointer flex items-center text-black text-base underline"
          >
            English (EN)
          </a>
        </div>
      </div>
    </div>
  );
};

export default Language;
