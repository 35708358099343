import React from "react";
import ClientPhoto from "./Media/clientphotos3.png";
import ClientPhoto2 from "./Media/clientphotos2.png";

interface Props {
  hideIdContent: boolean;
}

const ClientCard = ({ hideIdContent }: Props) => {
  return (
    <div className="flex bg-[#2C4854] w-full py-16 items-center justify-center flex-col">
      <h2 className="text-white font-bold text-3xl mb-5">OUR Testimonial</h2>
      <div className="relative">
        {!hideIdContent ? (
          <img src={ClientPhoto2} alt="" className="w-[80vw]" />
        ) : (
          <img src={ClientPhoto} alt="" className="w-[80vw]" />
        )}
      </div>
    </div>
  );
};

export default ClientCard;
