import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BlokHalus from "./media/BlokHalus.jpg";
import BlokKasar from "./media/BlokKasar.jpg";
import JalanDasar from "./media/JalanDasar.jpg";
import Logo from "./media/Logo.jpg";
import HangTag from "./media/HangTag.jpg";
import LabelPrinting from "./media/LabelPrinting.jpeg";
import { useTranslation } from "react-i18next";

const Content = () => {
  const navigate = useNavigate();
  const { t: translation } = useTranslation();
  const location = useLocation();

  const changePage = (toPage: string) => {
    let page: string = location.pathname;
    // console.log(page.slice(-3));
    if (toPage === "halus") {
      navigate("/blok-halus" + page.slice(-3));
    } else if (toPage === "kasar") {
      navigate("/blok-kasar" + page.slice(-3));
    } else if (toPage === "jalan") {
      navigate("/jalan-dasar" + page.slice(-3));
    } else if (toPage === "logo") {
      navigate("/logo" + page.slice(-3));
    } else if (toPage === "hangtag") {
      navigate("/hang-tag" + page.slice(-3));
    } else if (toPage === "label") {
      navigate("/label-printing" + page.slice(-3));
    }
  };

  return (
    <div className="w-full bg-warnaUtama justify-center flex my-5 py-10">
      {/* Grid Display */}
      <div className="grid md:grid-cols-3 grid-cols-1 lg:mx-32 gap-5 justify-between px-16 lg:px-32">
        {/* Blok Halus */}
        <div
          className="bg-white p-4 flex flex-col rounded-tr-xl rounded-bl-xl min-h-[400px] cursor-pointer"
          onClick={() => changePage("halus")}
        >
          {/* Gambar */}
          <img src={BlokHalus} alt="" className="w-full h-full mb-4" />
          {/* Text */}
          <div className="text-center">
            <h4 className="text-2xl font-bold text-gray-900">
              {" "}
              {translation("canvasModelBlockHalus")}
            </h4>
            <p className="text-xs text-gray-700">
              {translation("products.BlokHalusDescription")}
            </p>
          </div>
        </div>

        {/* Blok Kasar */}
        <div
          className="bg-white min-h-[400px] p-4 flex flex-col rounded-tr-xl rounded-bl-xl cursor-pointer"
          onClick={() => changePage("kasar")}
        >
          {/* Gambar */}
          <img src={BlokKasar} alt="" className="w-full h-full mb-4" />
          {/* Text */}
          <div className="text-center">
            <h4 className="text-2xl font-bold text-gray-900">
              {" "}
              {translation("canvasModelBlockKasar")}
            </h4>
            <p className="text-xs text-gray-700">
              {translation("products.BlokKasarDescription")}
            </p>
          </div>
        </div>

        {/* Jalan Dasar */}
        <div
          className="bg-white min-h-[400px] p-4 flex flex-col rounded-tr-xl rounded-bl-xl cursor-pointer"
          onClick={() => changePage("jalan")}
        >
          {/* Gambar */}
          <img src={JalanDasar} alt="" className="w-full h-full mb-4" />
          {/* Text */}
          <div className="text-center">
            <h4 className="text-2xl font-bold text-gray-900">
              {" "}
              {translation("canvasModelBlockJalan")}
            </h4>
            <p className="text-xs text-gray-700">
              {translation("products.JalanDasarDescription")}
            </p>
          </div>
        </div>

        {/* Logo */}
        <div
          className="bg-white min-h-[400px] p-4 flex flex-col rounded-tr-xl rounded-bl-xl cursor-pointer"
          onClick={() => changePage("logo")}
        >
          {/* Gambar */}
          <img src={Logo} alt="" className="w-full h-full mb-4" />
          {/* Text */}
          <div className="text-center">
            <h4 className="text-2xl font-bold text-gray-900">Logo</h4>
            <p className="text-xs text-gray-700">
              {translation("products.LogoDescription")}
            </p>
          </div>
        </div>

        {/* HangTag */}
        <div
          className="bg-white min-h-[400px] p-4 flex flex-col rounded-tr-xl rounded-bl-xl cursor-pointer"
          onClick={() => changePage("hangtag")}
        >
          {/* Gambar */}
          <img src={HangTag} alt="" className="w-full h-full mb-4" />
          {/* Text */}
          <div className="text-center">
            <h4 className="text-2xl font-bold text-gray-900">Hangtag</h4>
            <p className="text-xs text-gray-700">
              {translation("products.HangTagDescription")}
            </p>
          </div>
        </div>

        {/* Label Printing */}
        <div
          className="bg-white min-h-[400px] p-4 flex flex-col rounded-tr-xl rounded-bl-xl cursor-pointer"
          onClick={() => changePage("label")}
        >
          {/* Gambar */}
          <img src={LabelPrinting} alt="" className="w-full h-full mb-4" />
          {/* Text */}
          <div className="text-center">
            <h4 className="text-2xl font-bold text-gray-900">Label Printing</h4>
            <p className="text-xs text-gray-700">
              {translation("products.LabelPrintingDescription")}
            </p>
          </div>
        </div>
      </div>
      {/* End Grid Display */}
    </div>
  );
};

export default Content;
