import React from "react";
// import Header from "./Header";
import Content from "./Content";
import Title from "./Title";

const index = () => {
  return (
    <div>
      {/* <Header /> */}
      <Title />
      <Content />
    </div>
  );
};

export default index;
