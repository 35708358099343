import satin from "./media/satin.png";
import label2 from "./media/label2.png";
import labelprintings from "./media/labelprintings.jpg";
import satin1 from "./media/satin1.jpg";
import satin2 from "./media/satin2.jpg";
import satin3 from "./media/satin3.jpg";
import satin4 from "./media/satin4.jpg";
import satin5 from "./media/satin5.jpg";

import { useTranslation } from "react-i18next";

const Index = () => {
  const { t: translation } = useTranslation();

  return (
    <>
      <div className="mx-30 my-6 py-3 text-center">
        <h2 className="md:text-5xl font-bold mb-4 text-4xl">
          Label Satin & Nylon Printing
        </h2>
        <div className="text-gray-600 mx-4 sm:mx-0 flex flex-row justify-center items-center">
          <p className="md:max-w-sm">
            {translation("products.LabelPrinting.SubTitle")}
          </p>
        </div>
      </div>

      {/* Green background Section */}
      <div className="bg-[#2C4854] py-6">
        {/* Satin Printing x Nylon Printing */}
        <div className="grid grid-cols-1 md:grid-cols-2 mx-4 my-6 justify-center items-center">
          <div className="relative p-6">
            <img src={satin} alt="1" />
          </div>
          <div className="md:bg-white md:text-gray-900 text-white p-6 flex flex-col justify-center h-full">
            <h2 className="text-4xl font-semibold">Satin Printing</h2>
            <p className="text-base md:max-w-xs">
              {translation("products.LabelPrinting.SatinPrintingDescription")}
            </p>
          </div>
          {/* Row 2 */}
          <div className="bg-white md:bg-transparent relative p-6 md:order-4">
            <img src={label2} alt="1" />
          </div>
          <div className="bg-white p-6 md:order-3 md:text-right md:items-end flex flex-col justify-center h-full">
            <h2 className="text-4xl font-semibold">Nylon Printing</h2>
            <p className="text-base md:max-w-xs">
              {translation("products.LabelPrinting.NylonPrintingDescription")}
            </p>
          </div>
        </div>

        {/* Cara Kerja */}
        <div className="bg-white p-4 my-6">
          <div className="lg:mx-40 gap-10 flex md:flex-row flex-col">
            {/* Text */}
            <div className="text-left gap-6 flex flex-col justify-center ">
              <h2 className="text-4xl font-bold">
                {translation("products.LabelPrinting.HowItsWork")}
              </h2>
              <p>
                {translation("products.LabelPrinting.HowItsWorkDescription1")}
              </p>

              <p>
                {translation("products.LabelPrinting.HowItsWorkDescription2")}
              </p>
            </div>
            {/* Image */}
            <div className="relative py-6">
              <img src={labelprintings} alt="labelprintings" />
            </div>
          </div>
        </div>

        {/* Examples */}
        <div className="bg-white rounded-md mx-4 flex flex-col text-center my-5 pb-5">
          <h2 className="md:text-3xl text-2xl font-bold my-6 text-gray-700">
            {translation("products.LabelPrinting.ExampleTitle")}
          </h2>
          <div className="px-6 lg:mx-32 py-3 flex gap-5 items-center flex-col md:flex-row justify-center text-white ">
            <div className="bg-slate-200 ">
              <img src={satin1} alt="" className="w-full h-full" />
            </div>
            <div className="bg-slate-200">
              <img src={satin2} alt="" className="w-full h-full" />
            </div>
            <div className="bg-slate-200">
              <img src={satin3} alt="" className=" h-full w-full object-fill" />
            </div>
            <div className="bg-slate-200">
              <img src={satin4} alt="" className="h-full w-full" />
            </div>
            <div className="bg-slate-200">
              <img src={satin5} alt="" className="w-full h-full" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
