import Logos from "../media/logo/logos.jpg";
import image1 from "../media/logo/1.jpg";
import image2 from "../media/logo/2.jpg";
import image3 from "../media/logo/3.jpg";
import image4 from "../media/logo/logo1.png";
import image5 from "../media/logo/logo2.png";
import image6 from "../media/logo/logo3.jpg";
import image7 from "../media/logo/logo4.png";

import { useTranslation } from "react-i18next";

const Index = () => {
  const { t: translation } = useTranslation();

  return (
    <>
      <div className="mx-30 my-6 py-3 text-center">
        <h2 className="md:text-5xl font-bold mb-4 text-4xl">Logo</h2>
        <div className="text-gray-600 mx-4 sm:mx-0 flex flex-row justify-center items-center">
          <p className="md:max-w-sm">{translation("products.Logo.SubTitle")}</p>
        </div>
      </div>

      {/* Image */}
      <div className="w-full">
        <img src={Logos} alt="" className="w-full h-full" />
      </div>

      {/* A bit of Description */}
      <div className="flex bg-[#2C4854] text-center py-6 flex-col">
        <div className="py-3 flex mx-4 sm:mx-0 flex-row justify-center text-white">
          <p className="md:max-w-4xl tracking-wide">
            {translation("products.Logo.Description")}
          </p>
        </div>

        {/* HangTag Example */}
        <div className="bg-white rounded-md mx-4 flex flex-col text-center my-5 pb-5">
          <h2 className="md:text-3xl text-2xl font-bold my-6 text-gray-700">
            {translation("products.Logo.ExampleTitle")}
          </h2>
          <div className="px-10 py-3 flex gap-5 items-center flex-col xl:mx-32 lg:mx-16 md:flex-row justify-center text-white">
            <div className="bg-pink-300  min-w-[10vw]">
              {/* asd */}
              <img src={image1} alt="" className="w-full h-full" />
            </div>
            <div className="bg-slate-200 min-w-[10vw]">
              <img src={image2} alt="" className="w-full h-full" />
            </div>
            <div className="bg-slate-200 min-w-[10vw]">
              <img src={image3} alt="" className="w-full h-full" />
            </div>
            <div className="bg-slate-200 min-w-[10vw] md:max-h-56 md:min-h-56">
              <img src={image4} alt="" className="w-full md:max-h-56" />
            </div>
            <div className="bg-slate-200 min-w-[10vw]">
              <img src={image5} alt="" className="w-full md:max-h-56" />
            </div>
            <div className="bg-slate-200 min-w-[10vw]">
              <img src={image6} alt="" className="w-full h-full" />
            </div>
            <div className="bg-slate-200 min-w-[10vw]">
              <img src={image7} alt="" className="w-full md:max-h-56" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
