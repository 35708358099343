import React from "react";

const Map = () => {
  console.log(process.env.REACT_APP_GOOGLE_API_KEY);
  return (
    <div className="">
      <iframe
        className="w-full h-[50vh]"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
        &q=Mina+Label`}
      ></iframe>
      <iframe
        className="w-full h-[50vh] mt-2"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
        &q=Bank+Perkreditan+rakyat+ruko+tanah+abang`}
      ></iframe>
    </div>
  );
};

export default Map;
