import Header from "./Header";
import ContactUs from "../AboutUs/ContactUs";
import Map from "./Map";
import PaymentMethod from "./PaymentMethod";

const Index = () => {
  return (
    <div>
      <Header />
      <ContactUs />
      <PaymentMethod />
      <Map />
    </div>
  );
};

export default Index;
