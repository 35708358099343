import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { Button } from "@material-tailwind/react";
import Button from "@mui/material/Button";
const StickyWA = () => {
  const location = useLocation();
  const { t: translation } = useTranslation();

  const navigateTo = () => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: "whatsapp_click",
      action: "click_button",
      category: "social_share",
      label: "WhatsApp Share",
    });
    // window.dataLayer.push({
    //   event: "whatsapp_click",
    // });

    let page: string = location.pathname;
    if (page.slice(-3) === "/en") {
      window.open(
        "https://api.whatsapp.com/send?phone=6282188883321&text=Hello%20Mina%20Label..%20can%20I%20have%20chat%20for%20consultation..%20%E2%98%BA%EF%B8%8F",
        "mywindow"
      );
    } else {
      window.open(
        "https://api.whatsapp.com/send?phone=6287775669668&text=Hello%20Mina%20Label..%20mau%20tanya%20harga%20dan%20konsultasi%20dong%20..%20%E2%98%BA%EF%B8%8F",
        "mywindow"
      );
    }
  };
  return (
    <>
      <button
        onClick={navigateTo}
        className="rounded-full py-3 md:py-2 px-4 md:px-4 bg-green-500 bottom-24 md:bottom-11 right-0 mr-5 md:mr-12 fixed sm:hidden md:flex cursor-pointer items-center justify-center "
      >
        <FaWhatsapp color="white" size="2.2rem" />
        <h1 className="text-white ml-1 hidden md:flex">
          {translation("stickyWa")}
        </h1>
      </button>
      {/* <div
        onClick={navigateTo}
        className="rounded-full py-3 md:py-2 px-4 md:px-4 bg-green-500 bottom-24 md:bottom-11 right-0 mr-5 md:mr-12 fixed sm:hidden md:flex cursor-pointer items-center justify-center "
      >
        <FaWhatsapp color="white" size="2.2rem" />
        <h1 className="text-white ml-1 hidden md:flex">
          {translation("stickyWa")}
        </h1>
      </div> */}
      {/* <div
        onClick={navigateTo}
        className="rounded-full py-2 px-3 bg-green-500 bottom-24 right-0 mr-5 fixed flex md:hidden cursor-pointer items-center justify-center "
      >
        <FaWhatsapp color="white" size="2.3rem" />
      </div> */}
    </>
  );
};

export default StickyWA;
