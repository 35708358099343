import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegCalendarAlt, FaThumbsUp } from "react-icons/fa";
import { LiaPeopleCarrySolid } from "react-icons/lia";
import { LuLayers } from "react-icons/lu";
const WhyChooseUs = () => {
    const { t } = useTranslation();

    return (
        <div className="mx-6 xl:mx-48 py-10">
            <div className="justify-center flex text-center flex-col">
                <h2 className="text-warnaUtama font-bold text-5xl ">
                    Why Choose Us
                </h2>
                <h4 className="font-sans text-black my-3 text-lg tracking-wide ">
                    {t("aboutUsChooseUs1")}
                    <br />
                    {t("aboutUsChooseUs2")}
                </h4>
            </div>

            {/* Content */}
            <div className="grid gap-8 grid-cols-1 md:mx-14 md:grid-cols-2 justify-center items-center my-10 lg:mx-24">
                {/* Work Effeciency */}
                <div className="flex md:flex-row flex-col justify-center items-center gap-6">
                    <div className="relative">
                        <FaRegCalendarAlt
                            size="3.5rem"
                            style={{ opacity: ".6" }}
                        />
                    </div>
                    <div className="md:text-left text-center">
                        <div className="text-warnaUtama font-semibold text-xl md:text-2xl uppercase">
                            Work Efficiency
                        </div>
                        <div className="md:text-base text-xs mx-12 md:mx-0 max-w-xs md:w-full">
                            {t("aboutUsDetail1")}
                        </div>
                    </div>
                </div>

                {/* Friendly Service */}
                <div className="flex md:flex-row flex-col justify-center items-center gap-6">
                    <div className="relative">
                        <LiaPeopleCarrySolid
                            size="4rem"
                            style={{ opacity: ".6" }}
                        />
                    </div>
                    <div className="md:text-left text-center">
                        <div className="text-warnaUtama font-semibold text-xl md:text-2xl uppercase">
                            Friendly Service
                        </div>
                        <div className="md:text-base text-xs mx-12 md:mx-0 max-w-xs md:w-full">
                            {t("aboutUsDetail2")}
                        </div>
                    </div>
                </div>

                {/* Deusgn Assistance */}
                <div className="flex md:flex-row flex-col justify-center items-center gap-6">
                    <div className="relative">
                        <LuLayers size="4rem" style={{ opacity: ".6" }} />
                    </div>
                    <div className="md:text-left text-center">
                        <div className="text-warnaUtama font-semibold text-xl md:text-2xl uppercase">
                            Design Assistance
                        </div>
                        <div className="md:text-base text-xs mx-12 md:mx-0 max-w-xs md:w-full">
                            {t("aboutUsDetail3")}
                        </div>
                    </div>
                </div>

                {/* Quality */}
                <div className="flex md:flex-row flex-col justify-center items-center gap-6">
                    <div className="relative">
                        <FaThumbsUp size="4rem" style={{ opacity: ".6" }} />
                    </div>
                    <div className="md:text-left text-center">
                        <div className="text-warnaUtama font-semibold text-xl md:text-2xl uppercase">
                            Quality
                        </div>
                        <div className="md:text-base text-xs mx-12 md:mx-0 max-w-xs md:w-full">
                            {t("aboutUsDetail4")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyChooseUs;
