import { useTranslation } from "react-i18next";

interface Props {
  content: string;
}

const Header = ({ content }: Props) => {
  const { t: translation } = useTranslation();
  return (
    <div className="py-12 text-center lg:px-32 xl:px-48 sm:mx-16 mx-4">
      <h2 className="text-5xl text-gray-900 font-bold my-5">
        {content === "halus" && translation("canvasModelBlockHalus")}
        {content === "kasar" && translation("canvasModelBlockKasar")}
        {content === "jalan" && translation("canvasModelBlockJalan")}
      </h2>
      <p className="text-gray-700 px-4 text-sm">
        {content === "halus" &&
          translation("products.BlokHalus.HeaderDescription1")}
        {content === "kasar" &&
          translation("products.BlokHalus.HeaderDescription2")}
        {content === "jalan" &&
          translation("products.BlokHalus.HeaderDescription3")}
      </p>
    </div>
  );
};

export default Header;
