// import ReactPlayer from "react-player/youtube";
import Produk1 from "./Media/hotcut.jpg";
import Produk2 from "./Media/lasercut.jpg";
import Produk3 from "./Media/design.jpg";
import produk4 from "./Media/ultrasonic.jpg";
import { useTranslation } from "react-i18next";

const Content = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-warnaUtama">
      <div className="xl:mx-52 md:mx-20 mx-14 grid gap-3 gap-y-6 grid-cols-1 lg:grid-cols-4 justify-between py-10">
        <div className="bg-white rounded-lg flex flex-col border-4 border-warnaUtama">
          <div className="h-[65%] w-auto m-2 bg-white">
            <img src={Produk1} alt="" className="w-full h-full" />
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-black">Hot Cut</h1>
            <h1 className="text-black my-2"> {t("servicesHotCut")}</h1>
          </div>
        </div>
        <div className="bg-white rounded-lg flex flex-col border-4 border-warnaUtama">
          <div className="h-[65%] w-auto m-2 bg-white">
            <img src={Produk2} alt="" className="w-full h-full" />
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-black">Laser Cut</h1>
            <h1 className="text-black my-2">{t("servicesLaserCut")}</h1>
          </div>
        </div>
        <div className="bg-white rounded-lg flex flex-col border-4 border-warnaUtama">
          <div className="h-[65%] w-auto m-2 bg-white">
            <img src={Produk3} alt="" className="w-full h-full" />
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-black">Design</h1>
            <h1 className="text-black my-2">{t("servicesDesign")}</h1>
          </div>
        </div>
        <div className="bg-white rounded-lg flex flex-col border-4 border-warnaUtama">
          <div className="h-[65%] w-auto m-2 bg-white">
            <img src={produk4} alt="" className="w-full h-full" />
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-black">Ultra Sonic</h1>
            <h1 className="text-black my-2">{t("servicesUltraSonic")}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
