import HangTag1 from "./media/Hangtag1.png";
import image1 from "./media/hangtag1.jpg";
import image2 from "./media/hangtag2.jpg";
import image3 from "./media/hangtag-2.jpg";
import image4 from "./media/hangtag4.png";
import image5 from "./media/hangtag5.jpg";
import image6 from "./media/hangtag6.png";

import { useTranslation } from "react-i18next";

const Index = () => {
  const { t: translation } = useTranslation();

  return (
    <>
      <div className="mx-30 my-6 py-3 text-center">
        <h2 className="md:text-5xl font-bold mb-4 text-4xl">Hangtag</h2>
        <div className="text-gray-600 mx-4 sm:mx-0 flex flex-row justify-center items-center">
          <p className="md:max-w-sm">
            {translation("products.HangTag.SubTitle")}
          </p>
        </div>
      </div>

      {/* Image for Moblie */}
      <div className="relative md:hidden flex flex-row items-center justify-center bg-white p-2">
        <img src={HangTag1} alt="Hangtag1" className="w-full" />
      </div>

      <div className="bg-[#2C4854] py-6">
        {/* A bit of Description */}
        <div className="flex px-6 gap-6 text-center py-6 flex-row justify-center items-center">
          {/* Image for Tablet ,Laptop, PC  */}
          <div className="relative hidden md:flex flex-row items-center justify-center bg-white p-4">
            <img src={HangTag1} alt="Hangtag1" className="h-80 w-80" />
          </div>
          <div className="py-3 text-white text-left flex flex-row justify-center items-center">
            <p className="tracking-wide max-w-lg">
              {translation("products.HangTag.Description")}
            </p>
          </div>
        </div>

        {/* HangTag Example */}
        <div className="bg-white rounded-md mx-4 flex flex-col text-center my-5 pb-5">
          <h2 className="md:text-3xl text-2xl font-bold my-6 text-gray-700">
            {translation("products.HangTag.ExampleTitle")}
          </h2>
          <div className="px-6 lg:mx-32 py-3 flex gap-5 items-center flex-col md:flex-row justify-center text-white">
            <div className="bg-slate-200 md:min-w-[12vw] md:max-w-[12vw]">
              <img src={image1} alt="" className="w-full h-full" />
            </div>
            <div className="bg-slate-200 md:min-w-[12vw] md:max-w-[12vw]">
              <img src={image2} alt="" className="w-full h-full" />
            </div>
            <div className="bg-slate-200 md:min-w-[12vw] md:max-w-[12vw]">
              <img src={image3} alt="" className="w-full h-full" />
            </div>
            <div className="bg-slate-200 md:min-w-[12vw] md:max-w-[12vw]">
              <img src={image4} alt="" className="w-full h-full" />
            </div>
            <div className="bg-slate-200 md:min-w-[12vw] md:max-w-[12vw]">
              <img src={image5} alt="" className="w-full h-full" />
            </div>
            <div className="bg-slate-200 md:min-w-[12vw] md:max-w-[12vw]">
              <img src={image6} alt="" className="w-full h-full" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
