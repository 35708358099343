import React,{useEffect} from "react";
import "./App.css";
import Example from "./components/Header";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import StickyWA from "./components/StickyWA";
import Home from "./Home";
import About from "./AboutUs";
import Footer from "./components/Footer";
import Services from "./Services";
import Products from "./Products";
import ContactUs from "./ContactUsPage";
import BlokHalus from "./Products/BlokHalus";
import Logo from "./Products/Logo";
import HangTag from "./Products/HangTag";
import LabelPrinting from "./Products/LabelPrinting";
import FAQ from "./FAQ";
import LiveDesign from "./LiveDesign";
import Language from "./components/Language";
import TagManager from "react-gtm-module";

function App() {
  // const location = useLocation(); // Get current route

  useEffect(() => {
    TagManager.initialize({
      gtmId: "GTM-K8W96F9K",
    });
  }, []);
  return (
    <>
      <Router>
        <Example />
        <Language />
        <Routes>
          <Route>
            {/* <Route path="/" element={<Navigate to="/en" />} /> */}
            <Route path="/en" element={<Home />} />
            <Route path="/id" element={<Home />} />

            <Route path="/" element={<Home />} />

            <Route path="/about-us/en" element={<About />} />
            <Route path="/about-us/id" element={<About />} />
            <Route path="/services/en" element={<Services />} />
            <Route path="/services/id" element={<Services />} />
            <Route path="/products/en" element={<Products />} />
            <Route path="/products/id" element={<Products />} />
            {/* <Route path="/logo/en" element={<Logo />} /> izin pindahin kebawah mas */}
            <Route path="/contact-us/en" element={<ContactUs />} />
            <Route path="/contact-us/id" element={<ContactUs />} />
            {/* FAQ Page */}
            <Route path="/faq/en" element={<FAQ />} />
            <Route path="/faq/id" element={<FAQ />} />
            {/* Live Design Page */}
            <Route path="/design/en" element={<LiveDesign />} />
            <Route path="/design/id" element={<LiveDesign />} />
            {/* Product sub routes */}
            <Route path="/blok-halus/id" element={<BlokHalus />} />
            <Route path="/blok-halus/en" element={<BlokHalus />} />
            <Route path="/blok-kasar/id" element={<BlokHalus />} />
            <Route path="/blok-kasar/en" element={<BlokHalus />} />
            <Route path="/jalan-dasar/id" element={<BlokHalus />} />
            <Route path="/jalan-dasar/en" element={<BlokHalus />} />
            <Route path="/logo/id" element={<Logo />} />
            <Route path="/logo/en" element={<Logo />} />
            <Route path="/hang-tag/id" element={<HangTag />} />
            <Route path="/hang-tag/en" element={<HangTag />} />
            <Route path="/label-printing/id" element={<LabelPrinting />} />
            <Route path="/label-printing/en" element={<LabelPrinting />} />
            {/* End of Poduct sub routes */}
            {/* <Route path=":lang(en)" element={<Home />} />
            <Route path=":lang(id)" element={<Home />} /> */}
          </Route>
          {/* </Route> */}
        </Routes>
        <Footer />
        <StickyWA />
      </Router>
    </>
  );
}

export default App;
