import React from "react";
import { TypeAnimation } from "react-type-animation";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
interface Props {
  hideIdContent: boolean;
}

const AnimationText = ({ hideIdContent }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col w-full px-6 md:px-20 justify-center bg-[#2C4854] p-3 py-6">
      <div className="flex flex-col md:flex-row justify-between text-center md:text-left gap-6 items-center py-6">
        <h2 className="font-serif font-bold tracking-wide text-2xl text-white">
          Your One Stop <br></br>Labeling Solutions
        </h2>
        <div className="">
          <h3 className="text-white font-sans text-xl font-semibold">
            100,000+
          </h3>
          <h6 className="text-white font-sans text-xs font-normal">
            Satisfied Brands
          </h6>
        </div>
        <div className="">
          <h3 className="text-white font-sans text-xl font-semibold">39+</h3>
          <h6 className="text-white font-sans text-xs font-normal">
            Years Of Experience
          </h6>
        </div>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
          }}
        >
          Contact Us
        </Button>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center py-6 font-serif font-bold tracking-wide text-base text-white">
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            t("animationText"),
            1000, // wait 1s before replacing "Mice" with "Hamsters"
            "",
          ]}
          wrapper="span"
          speed={50}
          style={{
            fontSize: "2em",
            display: "inline-block",
            color: "white",
            lineHeight: "1",
          }}
          repeat={Infinity}
        />
      </div>

      {!hideIdContent && (
        <div className="flex flex-col md:flex-row items-center justify-center py-6 font-serif font-bold tracking-wide text-base text-white">
          <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed out once, initially
              "Start small with 100 pieces, priced at just $37!",
              5000, // wait 1s before replacing "Mice" with "Hamsters"
              "",
            ]}
            wrapper="span"
            speed={50}
            style={{
              fontSize: "2em",
              display: "inline-block",
              color: "white",
              lineHeight: "1",
            }}
            repeat={Infinity}
          />
        </div>
      )}
    </div>
  );
};

export default AnimationText;
