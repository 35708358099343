import React from "react";
import Pabrik from "./Media/pabrik.jpg";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

const AboutUsSection = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="flex px-3 md:px-6 py-6 flex-col md:flex-row items-center justify-center">
            <div className="md:max-w-md">
                <img src={Pabrik} alt="" className="md:h-auto w-full" />
            </div>
            <div className="mt-5 ml-4 relative">
                <div className="font-semibold font-sans text-2xl">About Us</div>
                <div className="mt-6 md:max-w-xs">{t("aboutUs")}</div>
                {/* <div className="absolute bottom-7">
                    <Button
                        variant="contained"
                        // size="small"
                        sx={{
                            backgroundColor: "lightgray",
                            color: "black",
                            fontWeight: "bold",
                        }}
                    >
                        Contact Us
                    </Button>
                </div> */}
            </div>
        </div>
    );
};

export default AboutUsSection;
