import React from "react";
import FotoPerbandingan from "./Media/contohkasar.jpg";
import FotoPerbandinganEng from "./Media/fotoPerbandinganEng.jpg";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface Props {
  hideIdContent: boolean;
}

const PerbandinganKualitas = ({ hideIdContent }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="md:px-16 lg:px-40 px-10 py-6">
      <h1 className="text-warnaUtama font-bold text-3xl mb-5">
        {t("perbandinganKualitasHeader")}
      </h1>
      {!hideIdContent ? (
        <img src={FotoPerbandinganEng} alt="" />
      ) : (
        <img src={FotoPerbandingan} alt="" />
      )}
    </div>
  );
};

export default PerbandinganKualitas;
