import React, { Suspense } from "react";
import { Canvas } from "react-three-fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";

function ModelHalus(props: any) {
  const { scene } = useGLTF("/MLBlokHalus.glb");
  return <primitive object={scene} />;
}
function ModelKasar(props: any) {
  const { scene } = useGLTF("/MLBlokKasar.glb");
  return <primitive object={scene} />;
}
function ModelJalan(props: any) {
  const { scene } = useGLTF("/MLJalanDasar.glb");
  return <primitive object={scene} />;
}

const CanvasModel = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const changePage = (toPage: string) => {
    let page: string = location.pathname;
    // console.log(page.slice(-3));
    if (toPage === "halus") {
      navigate("/blok-halus" + page.slice(-3));
    } else if (toPage === "kasar") {
      navigate("/blok-kasar" + page.slice(-3));
    } else if (toPage === "jalan") {
      navigate("/jalan-dasar" + page.slice(-3));
    }
  };
  return (
    <div className="h-auto px-6 md:px-16 py-6">
      {/* Title */}
      <div className="my-4 flex flex-col">
        <h2 className="text-3xl font-bold">{t("canvasModelHeader")}</h2>
        <h4 className="font-sans text-slate-500 my-3">
          {t("canvasModelDetail")}
        </h4>
        <div className="flex item-center justify-center">
          <h4 className="font-sans text-slate-500 mb-3 font-bold text-lg ">
            {t("canvasModelSubDetail")}
          </h4>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-3 justify-between lg:px-20">
        {/* Item 1 */}
        <div className="bg-[#39748D] flex flex-col">
          <div className="md:min-h-[280px] w-auto m-2 bg-white">
            <Canvas
              className="aspect-square bg-white md:min-h-[280px] overflow-hidden"
              camera={{ position: [5, 5, 5], fov: 50 }}
            >
              <ambientLight intensity={1} />
              <Suspense fallback={null}>
                <ModelHalus />
              </Suspense>
              <OrbitControls />
            </Canvas>
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-white">
              {t("canvasModelBlockHalus")}
            </h1>
            <h1 className="text-white my-2">
              {t("canvasModelBlockHalusDesc")}
            </h1>
            <Button
              variant="outlined"
              sx={{
                color: "white",
                outlineColor: "white",
                borderColor: "white",
              }}
              onClick={() => changePage("halus")}
            >
              Learn More
            </Button>
          </div>
        </div>

        {/* Item 2 */}
        <div className="bg-[#3B6475]">
          <div className="md:min-h-[280px] w-auto m-2 bg-white">
            <Canvas
              className="aspect-square bg-white md:min-h-[280px] overflow-hidden"
              camera={{ position: [5, 5, 5], fov: 50 }}
            >
              <ambientLight intensity={1} />
              <Suspense fallback={null}>
                <ModelKasar />
              </Suspense>
              <OrbitControls />
            </Canvas>
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-white">
              {t("canvasModelBlockKasar")}
            </h1>
            <h1 className="text-white my-2">
              {t("canvasModelBlockKasarDesc")}
            </h1>
            <Button
              variant="outlined"
              sx={{
                color: "white",
                outlineColor: "white",
                borderColor: "white",
              }}
              onClick={() => changePage("kasar")}
            >
              Learn More
            </Button>
          </div>
        </div>

        {/* Item 3 */}
        <div className="bg-[#2C4854]">
          <div className="md:min-h-[280px] w-auto m-2 bg-white">
            <Canvas
              className="aspect-square bg-white md:min-h-[280px] overflow-hidden"
              camera={{ position: [5, 5, 5], fov: 50 }}
            >
              <ambientLight intensity={1} />
              <Suspense fallback={null}>
                <ModelJalan />
              </Suspense>
              <OrbitControls />
            </Canvas>
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-white">
              {t("canvasModelBlockJalan")}
            </h1>
            <h1 className="text-white my-2">
              {t("canvasModelBlockJalanDesc")}
            </h1>
            <Button
              variant="outlined"
              sx={{
                color: "white",
                outlineColor: "white",
                borderColor: "white",
              }}
              onClick={() => changePage("jalan")}
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanvasModel;
