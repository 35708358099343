import React from "react";
import Header from "./Header";
import Content from "./Content";
import WhyChooseUs from "./WhyChooseUs";
import ContactUs from "./ContactUs";

const About = () => {
  return (
    <div className="flex justify-center flex-col">
      <Header />
      <Content />
      <WhyChooseUs />
      <ContactUs />
    </div>
  );
};

export default About;
