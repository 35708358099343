import React from "react";
import { useState, ChangeEvent } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Services/styletemp.css";
import celanaPendek from "./Media/celanaPendek.png";
import baju3 from "./Media/baju3.png";
import dress from "./Media/dress.png";
import topi from "./Media/topi.png";
import Button from "@mui/material/Button";
import "../fonts/fonts.css";
const Content = () => {
  const [labelText, setLabelText] = useState<string>("Label");
  const [labelColor, setLabelColor] = useState<string>("#52595D");
  const [labelFont, setLabelFont] = useState<string>("Arial");
  const [showImage, setShowImage] = useState<string>("sweater");
  const [fontColor, setFontColor] = useState<string>("#000000");

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 10) {
      setLabelText(event.target.value);
    }
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabelColor(event.target.value);
  };
  const handleColorChangeFont = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFontColor(event.target.value);
  };

  const handleFontChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setLabelFont(event.target.value);
  };

  return (
    <div className="flex h-auto flex-col md:flex-row px-5 md:px-32 w-full mb-28 ">
      <div className="h-full w-[300px] md:w-[400px]">
        <ul className="flex">
          <li
            className="mx-2 underline cursor-pointer hover:text-blue-600"
            onClick={() => setShowImage("sweater")}
          >
            Sweater
          </li>
          <li
            className="mx-2 underline cursor-pointer hover:text-blue-600"
            onClick={() => setShowImage("dress")}
          >
            dress
          </li>
          <li
            className="mx-2 underline cursor-pointer hover:text-blue-600"
            onClick={() => setShowImage("pants")}
          >
            pants
          </li>
          <li
            className="mx-2 underline cursor-pointer hover:text-blue-600"
            onClick={() => setShowImage("hat")}
          >
            Hat
          </li>
        </ul>
        <div className="md:w-[35vw]">
          {showImage === "sweater" && (
            <div className="relative h-[60vh] flex items-center">
              <img
                src={baju3}
                alt=""
                className="h-full w-full object-cover absolute"
              />
              <div
                className={`absolute w-full top-[11%] inline-flex justify-center items-center`}
                // style={{ backgroundColor: labelColor }}
              >
                <h2
                  className="text-sm rounded-sm px-2"
                  style={{
                    fontFamily: labelFont,
                    backgroundColor: labelColor,
                    color: fontColor,
                  }}
                >
                  {labelText}
                </h2>
              </div>
            </div>
          )}
          {showImage === "dress" && (
            <div className="relative h-[60vh]">
              <img
                src={dress}
                alt=""
                className="h-full w-full object-cover absolute"
              />
              <div
                className={`absolute top-[16.5%] w-full h-5 inline-flex justify-center items-center`}
                // style={{ left: 12 }}
              >
                <h2
                  className={`text-sm items-center justify-center flex rounded-sm px-2`}
                  style={{
                    fontFamily: labelFont,
                    backgroundColor: labelColor,
                    color: fontColor,
                  }}
                >
                  {labelText}
                </h2>
              </div>
            </div>
          )}
          {showImage === "pants" && (
            <div className="relative h-[60vh]">
              <img
                src={celanaPendek}
                alt=""
                className="h-full w-full object-cover absolute"
              />
              <div
                className={`absolute top-[19.5%] w-full inline-flex justify-center items-center left-2 h-6`}
                // style={{ backgroundColor: labelColor }}
              >
                <h2
                  className="text-sm rounded-sm px-2"
                  style={{
                    backgroundColor: labelColor,
                    fontFamily: labelFont,
                    color: fontColor,
                  }}
                >
                  {labelText}
                </h2>
              </div>
            </div>
          )}
          {showImage === "hat" && (
            <div className="relative h-[60vh]">
              <img
                src={topi}
                alt=""
                className="h-full w-full object-cover absolute"
              />
              <div
                className={`absolute bottom-[19%] w-[100px] items-center justify-center inline-flex left-[58%] sm:left-[58%] xl:left-[58%] h-6`}
                // style={{ backgroundColor: labelColor }}
              >
                <h2
                  className="text-sm px-2 items-center justify-center flex rounded-sm"
                  style={{
                    backgroundColor: labelColor,
                    fontFamily: labelFont,
                    color: fontColor,
                  }}
                >
                  {labelText}
                </h2>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="md:w-[50%] h-full md:ml-52">
        <form className="label-form">
          <div className=" flex flex-col m-14">
            <div className="mb-5">
              <label htmlFor="text-input" className="text-lg">
                Text :
              </label>
              <input
                className="text-lg border border-black ml-1"
                type="text"
                id="text-input"
                value={labelText}
                onChange={handleTextChange}
              />
            </div>
            <div className="mb-5 flex md:flex-row flex-col ">
              {/* <div className="flex-col items-center justify-center">
                <label htmlFor="text-input" className="text-lg ">
                  Height
                </label>
                <div className="flex">
                  <Button
                    variant="contained"
                    // size="small"
                    sx={{
                      backgroundColor: "lightgray",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    onClick={() => setPanjangShirt(panjangShirt + 1)}
                  >
                    +
                  </Button>
                  <Button
                    variant="contained"
                    // size="small"
                    sx={{
                      backgroundColor: "lightgray",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    -
                  </Button>
                </div>
              </div>
              <div className="flex-col items-center justify-center ml-0 md:ml-3 md:mt-0 mt-2">
                <label htmlFor="text-input" className="text-lg ">
                  Width
                </label>
                <div className="flex">
                  <Button
                    variant="contained"
                    // size="small"
                    sx={{
                      backgroundColor: "lightgray",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    +
                  </Button>
                  <Button
                    variant="contained"
                    // size="small"
                    sx={{
                      backgroundColor: "lightgray",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    -
                  </Button>
                </div>
              </div> */}
            </div>
            <div className="mb-5">
              {" "}
              <label htmlFor="color-input">Label Color:</label>
              <input
                type="color"
                id="color-input"
                value={labelColor}
                onChange={handleColorChange}
              />
            </div>
            <div className="mb-5">
              {" "}
              <label htmlFor="color-input">Font Color:</label>
              <input
                type="color"
                id="color-input"
                value={fontColor}
                onChange={handleColorChangeFont}
              />
            </div>
            <div className="">
              <label htmlFor="font-input">Font:</label>
              <select
                id="font-input"
                value={labelFont}
                onChange={handleFontChange}
              >
                <option value="Arial">Arial</option>
                <option value="Verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="formom">formom</option>
                <option value="solobae">solobae</option>
                <option value="spicyNoodle">spicyNoodle</option>
                <option value="duckSpicy">duckSpicy</option>
                <option value="chido">chido</option>
                <option value="oldScientific">oldScientific</option>
                <option value="futura">futura</option>
                <option value="openSans">openSans</option>
                <option value="archive">archive</option>
                <option value="testimonia">testimonia</option>
                <option value="niceHoney">niceHoney</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Content;
