import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import AnimationText from "./AnimationText";
import VideoPlayer from "./VideoPlayer";
import AboutUsSection from "./AboutUsSection";
import BattleCard from "./BattleCard";
import CanvasModel from "./CanvasModel";
import PerbandinganKualitas from "./PerbandinganKualitas";
import Footer from "../components/Footer";
import OurProduct from "./OurProduct";
import Testimoni from "./Testimoni";
import ClientCard from "./ClientCard";
const Home = () => {
  const { t, i18n } = useTranslation();

  const [hideIdContent, setHideIdContent] = useState<boolean>(false);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/en")) {
      setHideIdContent(false);
    } else {
      setHideIdContent(true);
      handleTrans("id");
    }
  }, [location.pathname]);

  const handleTrans = (code: string) => {
    i18n.changeLanguage(code);
  };
  // const handleTrans = (code: string) => {
  //   i18n.changeLanguage(code);
  // };
  return (
    <div className="flex flex-col relative">
      <VideoPlayer />
      <AnimationText hideIdContent={hideIdContent} />
      <AboutUsSection />
      <BattleCard hideIdContent={hideIdContent} />
      <CanvasModel />
      <PerbandinganKualitas hideIdContent={hideIdContent} />
      <OurProduct />
      {hideIdContent ? <Testimoni /> : <></>}
      <ClientCard hideIdContent={hideIdContent} />
    </div>
  );
};

export default Home;
