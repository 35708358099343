import { useTranslation } from "react-i18next";

const Header = () => {
    const { t } = useTranslation();

    return (
        <div className="py-16 mx-4 flex justify-center text-center flex-col">
            <h2 className="text-warnaUtama font-bold text-5xl ">About Us</h2>
            <h4 className="font-sans text-black my-3 text-lg">
                {t("aboutUsHeader")}
            </h4>
        </div>
    );
};

export default Header;
