import React from "react";
import bca from "./media/bca.png";
import paypal from "./media/paypal.png";
import mastervisa from "../components/visa-mastercard.jpg";
const PaymentMethod = () => {
  return (
    <div className="bg-warnaUtama mt-1">
      <div className="x-6 xl:mx-48 lg:mx-16 py-10 flex flex-col justify-center items-center">
        <div className="text-white text-4xl xl:text-5xl font-bold">
          Payment Method
        </div>
        <div className="flex md:flex-row flex-col md:gap-36 gap-10 py-6 justify-center items-center md:items-start">
          {/* Items 1 */}
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="md:ml-3 text-center md:text-start items-center justify-center">
              <img src={bca} alt="" className="w-56 h-20" />
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                (0019821821 - Palapa Cipta Mega P)
              </h1>
            </div>
          </div>

          {/* Items 2 */}
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="md:ml-3 text-center md:text-start">
              <img src={paypal} alt="" className="w-64 h-20" />
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2 items-center justify-center flex mt-2">
                (Paypal)
              </h1>
            </div>
          </div>

          {/* Items 3 */}
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="md:ml-3 text-center md:text-start">
              <img src={mastervisa} alt="" className="w-64 h-20" />
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2 items-center justify-center flex mt-2">
                (Master Card & Visa)
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
