import React from "react";
import Testi1 from "./Media/testi1.jpeg";
import Testi2 from "./Media/testi2.jpeg";
import Testi3 from "./Media/testi3.jpeg";
import Testi4 from "./Media/testi4.jpeg";
import Testi5 from "./Media/testi5.jpeg";
import Testi6 from "./Media/testi6.jpeg";
const Testimoni = () => {
    return (
        <div className="px-6 md:px-16 lg:px-40 py-3 mb-20">
            <h2 className="text-warnaUtama font-bold text-3xl mb-5">
                OUR Testimonial
            </h2>
            <div className="grid md:grid-cols-3 gap-6 grid-cols-1 justify-between">
                <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
                    <div className="h-full w-auto m-2 bg-white">
                        <img src={Testi1} alt="" className="w-full h-full" />
                    </div>
                </div>
                <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
                    <div className="h-full w-auto m-2 bg-white">
                        <img src={Testi2} alt="" className="w-full h-full" />
                    </div>
                </div>
                <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
                    <div className="h-full w-auto m-2 bg-white">
                        <img src={Testi3} alt="" className="w-full h-full" />
                    </div>
                </div>

                <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
                    <div className="h-full w-auto m-2 bg-white">
                        <img src={Testi4} alt="" className="w-full h-full" />
                    </div>
                </div>
                <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
                    <div className="h-full w-auto m-2 bg-white">
                        <img src={Testi5} alt="" className="w-full h-full" />
                    </div>
                </div>
                <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
                    <div className="h-full w-auto m-2 bg-white">
                        <img src={Testi6} alt="" className="w-full h-full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimoni;
