import React from "react";
import ReactPlayer from "react-player/youtube";
import { useTranslation } from "react-i18next";

const Content = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-warnaUtama">
            {/* Video Section */}
            <div className="py-10 xl:mx-40 flex flex-row justify-center items-center">
                <div className="relative aspect-video xl:min-h-[600px] w-full p-6">
                    <ReactPlayer
                        url={"https://youtu.be/XOcz9zUrLm4"}
                        height="100%"
                        width="100%"
                    />
                </div>
            </div>

            {/* Content */}
            <div className="text-white font-semibold font-sans text-lg mx-14 md:mx-16 pb-10 xl:mx-48">
                {t("aboutUsContent1")}
                <br />
                <br />
                {t("aboutUsContent2")}
                <br />
                <br />
                {t("aboutUsContent3")}
                <br />
                <br />
                {t("aboutUsContent4")}
                <br />
                <br />
                {t("aboutUsContent5")}
                <br />
                <br />
                {t("aboutUsContent6")}
            </div>
        </div>
    );
};

export default Content;
