import { useTranslation } from "react-i18next";

const Header = () => {
    const { t: translation } = useTranslation();

    return (
        <div className="pb-5 pt-12 text-center md:mx-40 lg:mx-60">
            <h2 className="text-5xl text-gray-900 font-bold my-5">
                Our Products
            </h2>
            <p className="text-gray-700 px-4 text-xl">
                {translation("ProductsHeaderDescriptions")}
            </p>
        </div>
    );
};

export default Header;
