import React from "react";
import { useTranslation } from "react-i18next";
import { BsFillTelephoneFill, BsWhatsapp } from "react-icons/bs";
import { TfiEmail } from "react-icons/tfi";
const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-warnaUtama">
      <div className="mx-6 xl:mx-48 lg:mx-16 py-10">
        {/* Items */}
        <div className="flex gap-6 py-6 flex-col justify-center items-center md:items-start">
          {/* Items 1 */}
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
              <h1 className="text-3xl font-bold">1</h1>
            </div>
            <div className="md:ml-3 text-center md:text-start">
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                Q: Proccess Time
              </h1>
              <h1 className="text-white">A: Woven Label : 3-4 working days</h1>
              <h1 className="text-white">A: Care Label : 4-7 working days</h1>
              <h1 className="text-white">A: Hangtag : 7-14 working days</h1>
            </div>
          </div>

          {/* Items 2 */}
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
              <h1 className="text-3xl font-bold">2</h1>
            </div>
            <div className="md:ml-3 text-center md:text-start">
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                {t("faq.Questions.2")}
              </h1>
              <h1 className="text-white">{t("faq.Answers.2")}</h1>
            </div>
          </div>

          {/* Items 3 */}
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
              <h1 className="text-3xl font-bold">3</h1>
            </div>
            <div className="md:ml-3 text-center md:text-start">
              <h1 className="text-white"> {t("faq.Questions.3")}</h1>
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                {t("faq.Answers.3")}
              </h1>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
              <h1 className="text-3xl font-bold">4</h1>
            </div>
            <div className="md:ml-3 text-center md:text-start">
              <h1 className="text-white">{t("faq.Questions.4")}</h1>
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                {t("faq.Answers.4")}
              </h1>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
              <h1 className="text-3xl font-bold">5</h1>
            </div>
            <div className="md:ml-3 text-center md:text-start">
              <h1 className="text-white">{t("faq.Questions.5")}</h1>
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                {t("faq.Answers.5")}
              </h1>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
              <h1 className="text-3xl font-bold">6</h1>
            </div>
            <div className="md:ml-3 text-center md:text-start">
              <h1 className="text-white">{t("faq.Questions.6")}</h1>
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                {t("faq.Answers.6")}
              </h1>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
              <h1 className="text-3xl font-bold">7</h1>
            </div>
            <div className="md:ml-3 text-center md:text-start">
              <h1 className="text-white">{t("faq.Questions.7")}</h1>
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                {t("faq.Answers.7")}
              </h1>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
              <h1 className="text-3xl font-bold">8</h1>
            </div>
            <div className="md:ml-3 text-center md:text-start">
              <h1 className="text-white">{t("faq.Questions.8")}</h1>
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                {t("faq.Answers.8")}
              </h1>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
              <h1 className="text-3xl font-bold">9</h1>
            </div>
            <div className="md:ml-3 text-center md:text-start">
              <h1 className="text-white">{t("faq.Questions.9")}</h1>
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                {t("faq.Answers.9")}
              </h1>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
              <h1 className="text-3xl font-bold">10</h1>
            </div>
            <div className="md:ml-3 text-center md:text-start">
              <h1 className="text-white">{t("faq.Questions.10")}</h1>
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                {t("faq.Answers.10")}
              </h1>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
            <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
              <h1 className="text-3xl font-bold">11</h1>
            </div>
            <div className="md:ml-3 text-center md:text-start">
              <h1 className="text-white">{t("faq.Questions.11")}</h1>
              <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                {t("faq.Answers.11")}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
