import { useTranslation } from "react-i18next";

const Title = () => {
    const { t } = useTranslation();

    return (
        <div className="py-16 items-center flex justify-center text-center flex-col">
            <div className="md:max-w-lg mx-6">
                <h2 className="text-warnaUtama font-bold text-5xl ">
                    Our Services
                </h2>
                <p className="font-sans text-black my-3 text-lg">
                    {t("servicesHeader")}
                </p>
            </div>
        </div>
    );
};

export default Title;
