import { useTranslation } from "react-i18next";

const Header = () => {
    const { t } = useTranslation();

    return (
        <div className="xl:mx-48 py-10 mx-6 sm:mx-28 flex justify-center text-center flex-col">
            <h2 className="text-warnaUtama font-bold text-5xl ">Contact Us</h2>
            <h4 className="font-sans text-black my-3 text-lg">
                {t("contactUs")}
            </h4>
        </div>
    );
};

export default Header;
