import React from "react";
import Button from "@mui/material/Button";
import Produk1 from "./Media/produk1.jpg";
import Produk2 from "./Media/produk2.jpg";
import Produk3 from "./Media/produk3.jpg";
import Produk4 from "./Media/produk4.jpg";
import Produk5 from "./Media/produk5.jpg";
import Produk6 from "./Media/produk6.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OurProduct = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const changePage = (toPage: string) => {
    let page: string = location.pathname;
    // console.log(page.slice(-3));
    if (toPage === "halus") {
      navigate("/blok-halus" + page.slice(-3));
    } else if (toPage === "kasar") {
      navigate("/blok-kasar" + page.slice(-3));
    } else if (toPage === "jalan") {
      navigate("/jalan-dasar" + page.slice(-3));
    } else if (toPage === "logo") {
      navigate("/logo" + page.slice(-3));
    } else if (toPage === "hangtag") {
      navigate("/hang-tag" + page.slice(-3));
    } else if (toPage === "label") {
      navigate("/label-printing" + page.slice(-3));
    }
  };
  return (
    <div className="md:px-16 lg:px-40 px-6 py-3">
      <h2 className="text-warnaUtama font-bold text-3xl mb-5">OUR PRODUCT</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 justify-between">
        <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
          <div className="h-[65%] w-auto m-2 bg-white">
            <img src={Produk1} alt="" className="w-full h-full" />
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-black">
              {t("canvasModelBlockHalus")}
            </h1>
            {/* <h1 className="text-white my-2">*******</h1> */}
            <Button
              variant="text"
              sx={{
                color: "grey",
              }}
              onClick={() => changePage("halus")}
            >
              Learn More
            </Button>
          </div>
        </div>
        <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
          <div className="h-[65%] w-auto m-2 bg-white">
            <img src={Produk2} alt="" className="w-full h-full" />
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-black">
              {t("canvasModelBlockKasar")}
            </h1>
            {/* <h1 className="text-white my-2">*******</h1> */}
            <Button
              variant="text"
              sx={{
                color: "grey",
              }}
              onClick={() => changePage("kasar")}
            >
              Learn More
            </Button>
          </div>
        </div>
        <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
          <div className="h-[65%] w-auto m-2 bg-white">
            <img src={Produk3} alt="" className="w-full h-full" />
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-black">
              {t("canvasModelBlockJalan")}
            </h1>
            {/* <h1 className="text-white my-2">*******</h1> */}
            <Button
              variant="text"
              sx={{
                color: "grey",
              }}
              onClick={() => changePage("jalan")}
            >
              Learn More
            </Button>
          </div>
        </div>

        <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
          <div className="h-[65%] w-auto m-2 bg-white">
            <img src={Produk4} alt="" className="w-full h-full" />
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-black">Logo</h1>
            {/* <h1 className="text-white my-2">*******</h1> */}
            <Button
              variant="text"
              sx={{
                color: "grey",
              }}
              onClick={() => changePage("logo")}
            >
              Learn More
            </Button>
          </div>
        </div>
        <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
          <div className="h-[65%] w-auto m-2 bg-white">
            <img src={Produk5} alt="" className="w-full h-full" />
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-black">
              Hangtag / Price Tag
            </h1>
            {/* <h1 className="text-white my-2">*******</h1> */}
            <Button
              variant="text"
              sx={{
                color: "grey",
              }}
              onClick={() => changePage("hangtag")}
            >
              Learn More
            </Button>
          </div>
        </div>
        <div className="bg-homeProdukBg flex flex-col border-4 border-warnaUtama">
          <div className="h-[65%] w-auto m-2 bg-white">
            <img src={Produk6} alt="" className="w-full h-full" />
          </div>
          <div className=" p-3">
            <h1 className="text-2xl font-bold text-black">Label Printing</h1>
            {/* <h1 className="text-white my-2">*******</h1> */}
            <Button
              variant="text"
              sx={{
                color: "grey",
              }}
              onClick={() => changePage("label")}
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProduct;
