import React from "react";
import { useTranslation } from "react-i18next";
import { BsFillTelephoneFill, BsWhatsapp } from "react-icons/bs";
import { TfiEmail } from "react-icons/tfi";
const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-warnaUtama">
      <div className="mx-6 xl:mx-48 lg:mx-16 py-10 flex">
        <div className="md:w-[50%]">
          <div className="text-white text-4xl xl:text-5xl font-bold">
            {t("aboutUsContactUs")}
          </div>

          {/* Items */}
          <div className="flex gap-6 py-6 flex-col justify-center items-center md:items-start">
            {/* Items 1 */}
            <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
              <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
                <BsFillTelephoneFill size="1.5rem" />
              </div>
              <div className="md:ml-3 text-center md:text-start">
                <h1 className="text-white">Phone</h1>
                <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                  {t("waNumber")}
                </h1>
              </div>
            </div>

            {/* Items 2 */}
            <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
              <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
                <TfiEmail size="1.5rem" />
              </div>
              <div className="md:ml-3 text-center md:text-start">
                <h1 className="text-white">Email</h1>
                <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                  {t("email")}
                </h1>
              </div>
            </div>

            {/* Items 3 */}
            <div className="flex flex-col md:flex-row justify-center gap-2 items-center">
              <div className="flex rounded-full w-14 h-14 md:w-16 md:h-16 bg-white opacity-80 items-center justify-center">
                <BsWhatsapp size="1.5rem" />
              </div>
              <div className="md:ml-3 text-center md:text-start">
                <h1 className="text-white">WhatsApp</h1>
                <h1 className="bottom-0 text-white md:text-xl font-bold md:mt-2">
                  {t("waNumber")}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
