import React, { Suspense } from "react";
import { Canvas } from "react-three-fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { useTranslation } from "react-i18next";
import woven1 from "./media/woven1.jpg";
import woven2 from "./media/woven2.jpg";
import woven3 from "./media/woven3.jpg";
import woven4 from "./media/woven4.jpg";
import woven5 from "./media/woven5.png";
import woven6 from "./media/woven6.png";
import woven7 from "./media/woven7.png";
import SlipLabel from "./media/SlipLabel.png";
import JahitKananKiri1 from "./media/JahitKananKiri1.png";
import JahitGantung from "./media/JahitGantung.png";
import JahitKeliling1 from "./media/JahitKeliling1.png";
import JahitPerahu1 from "./media/JahitPerahu1.png";

function ModelHalus(props: any) {
  const { scene } = useGLTF("/LabelWovenMLBlokHalus.glb");
  return <primitive object={scene} />;
}
function ModelKasar(props: any) {
  const { scene } = useGLTF("/LabelWovenMLBlokKasar.glb");
  return <primitive object={scene} />;
}
function ModelJalan(props: any) {
  const { scene } = useGLTF("/LabelWovenMLJalanDasar.glb");
  return <primitive object={scene} />;
}

const Content = () => {
  const { t: translation } = useTranslation();
  return (
    <div className="justify-center flex flex-col my-5 py-10">
      {/* Grid Display */}
      <div className="bg-warnaUtama py-6 grid grid-cols-1 px-6 gap-12 md:gap-6 md:grid-cols-3 justify-between lg:px-20">
        {/* Item 1 */}
        <div className="flex flex-col">
          <div className="m-2 bg-white">
            <Canvas
              className="aspect-square bg-white overflow-hidden"
              camera={{ position: [-10, 15, 15], fov: 50 }}
            >
              <ambientLight intensity={1} />
              <Suspense fallback={null}>
                <ModelHalus />
              </Suspense>
              <OrbitControls />
            </Canvas>
          </div>
          <div className="md:p-3 text-center text-white">
            <h1 className="font-bold text-xl md:text-2xl md:mb-0 mb-3">
              {translation("canvasModelBlockHalus")}
            </h1>
            <p className="visible md:hidden">
              {translation("products.BlokHalus.SubBlokHalusDescription")}
            </p>
          </div>
        </div>

        {/* Item 2 */}
        <div className="">
          <div className="m-2 bg-white">
            <Canvas
              className="aspect-square bg-white overflow-hidden"
              camera={{ position: [-10, 15, 15], fov: 50 }}
            >
              <ambientLight intensity={1} />
              <Suspense fallback={null}>
                <ModelKasar />
              </Suspense>
              <OrbitControls />
            </Canvas>
          </div>
          <div className="md:p-3 text-center text-white">
            <h1 className="text-xl md:text-2xl md:mb-0 font-bold mb-3">
              {translation("canvasModelBlockKasar")}
            </h1>
            <p className="visible md:hidden">
              {translation("products.BlokHalus.SubBlokKasarDescription")}
            </p>
          </div>
        </div>

        {/* Item 3 */}
        <div className="">
          <div className="md:m-2 bg-white">
            <Canvas
              className="aspect-square bg-white overflow-hidden"
              camera={{ position: [-10, 15, 15], fov: 50 }}
            >
              <ambientLight intensity={1} />
              <Suspense fallback={null}>
                <ModelJalan />
              </Suspense>
              <OrbitControls />
            </Canvas>
          </div>
          <div className="p-3 text-center text-white">
            <h1 className="text-xl md:text-2xl md:mb-0 mb-3 font-bold">
              {translation("canvasModelBlockJalan")}
            </h1>
            <p className="visible md:hidden">
              {translation("products.BlokHalus.SubJalanDasarDescription")}
            </p>
          </div>
        </div>
      </div>
      {/* End Grid Display */}

      {/* Grid Display 2 */}
      <div className="hidden md:grid grid-cols-1 gap-6 md:grid-cols-3 justify-between lg:px-20">
        {/* Item 1 */}
        <div className="p-3 text-justify text-gray-700">
          <p>{translation("products.BlokHalus.SubBlokHalusDescription")}</p>
        </div>

        {/* Item 2 */}
        <div className="p-3 text-justify text-gray-700">
          <p>{translation("products.BlokHalus.SubBlokKasarDescription")}</p>
        </div>

        {/* Item 3 */}
        <div className="p-3 text-justify text-gray-700">
          <p>{translation("products.BlokHalus.SubJalanDasarDescription")}</p>
        </div>
      </div>
      {/* End Grid Display 2 */}

      {/* Examples */}
      <div className="py-6 bg-warnaUtama px-4 text-warnaUtama justify-center items-center flex flex-col">
        <div className="bg-white py-4 lg:mx-40 text-center md:rounded-xl rounded-t-xl">
          <h2 className="md:text-3xl text-2xl font-bold mb-5">
            {translation("products.BlokHalus.ExampleTitle")}
          </h2>

          {/* Grid Items */}
          <div className="grid md:grid-cols-4 grid-cols-1 mx-6 gap-6">
            <div className="relative">
              <img src={woven1} alt="" className="w-full h-full mb-4" />
            </div>
            <div className="relative">
              <img src={woven2} alt="" className="w-full h-full mb-4" />
            </div>
            <div className="relative">
              <img src={woven3} alt="" className="w-full h-full mb-4" />
            </div>
            <div className="relative">
              <img src={woven4} alt="" className="w-full h-full mb-4" />
            </div>
            <div className="relative">
              <img src={woven5} alt="" className="w-full h-72 mb-4" />
            </div>
            <div className="relative">
              <img src={woven6} alt="" className="w-full h-72 mb-4" />
            </div>
            <div className="relative">
              <img src={woven7} alt="" className="w-full h-72 mb-4" />
            </div>
          </div>
        </div>
        <div className="bg-white max-w-xl font-bold p-4 text-center rounded-b-xl text-2xl">
          {translation("products.BlokHalus.ExampleSubTitle")}
        </div>

        {/* Labels */}
        <div className="grid lg:mx-40 grid-cols-1 md:grid-flow-row-dense justify-center items-center md:grid-cols-4 md:gap-6 my-6 p-4">
          <div className="relative p-2 gap-6 flex justify-center flex-col items-center">
            <img src={SlipLabel} alt="" className="w-full" />
            <label className="text-gray-100">
              {translation("products.BlokHalus.Labels.SlipLabel")}
            </label>
          </div>
          <div className="relative p-4 col-span-2 gap-6 flex justify-center flex-col items-center">
            <img src={JahitKananKiri1} alt="" className="w-full" />
            <label className="text-gray-100">
              {translation("products.BlokHalus.Labels.JahitanKananKiri")}
            </label>
          </div>
          <div className="relative gap-6 flex justify-center flex-col items-center">
            <img src={JahitGantung} alt="" className="w-full" />
            <label className="text-gray-100">
              {translation("products.BlokHalus.Labels.SlipLabel1")}
            </label>
          </div>
          <div className="relative col-span-2 gap-6 flex justify-center flex-col items-center">
            <img src={JahitKeliling1} alt="" className="w-full" />
            <label className="text-gray-100">
              {translation("products.BlokHalus.Labels.JahitanKeliling")}
            </label>
          </div>
          <div className="relative col-span-2 gap-6 flex justify-center flex-col items-center">
            <img src={JahitPerahu1} alt="" className="w-full" />
            <label className="text-gray-100">
              {translation("products.BlokHalus.Labels.JahitanPerahu")}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
