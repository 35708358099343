import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // language resources
  resources: {
    id: {
      translation: {
        waNumber: "+62 8777-5669-668",
        email: "minalabel88@gmail.com",
        stickyWa: "Hello Minalabel, Mau Tanya Ini",
        welcome: "Selamat Datang Bung",
        animationText: "Hanya 3 - 4 Hari Saja Untuk Pengerjaan Label Woven",
        aboutUs:
          "Kami telah berprofesi di bidang label hampir 39 tahun, dengan mesin-mesin yang khusus dan modern dan di operatorkan dengan pekerja-pekerja yang ahli serta pengalaman. Perusahaan kami tidak hanya memproduksi label saja, kami juga memproduksi hangtags (label kertas) dan printing (washing/care label) berbahan saten (tafeta & nylon)",
        canvasModelSubDetail:
          "Silakan memutar atau memperbesar gambar di bawah ini",
        canvasModelHeader: "Perbandingan Kualitas Produk",
        canvasModelDetail:
          "Kami memiliki bermacam-macam produk untuk memenuhi kebutuhan branding pada produk anda dan kami mampu menyesuaikan budget anda",
        canvasModelBlockHalus: "Block Halus",
        canvasModelBlockHalusDesc:
          "Rajutan benang yang paling padat pada label woven",
        canvasModelBlockKasar: "Block Kasar",
        canvasModelBlockKasarDesc:
          "Setengah rajutan full yang menyerupai block halus",
        canvasModelBlockJalan: "Jalan Dasar",
        canvasModelBlockJalanDesc: "Rajutan benang yang paling renggang",
        perbandinganKualitasHeader:
          "Detail perbandingan Kualitas Antara Block Halus, Block Kasar dan Jalan Dasar",
        aboutUsHeader:
          "Kami menyediakan produk label industri terbaik di pasar dengan kualitas terbaik",
        aboutUsContent1:
          "PT. Palapa Cipta Mega Pesona adalah perusahaan yang bergerak di bidang jasa manufacturing branding untuk garment sejak tahun 1983, Kami juga memiliki anak perusahaan Mina Label yang bergerak di bidang jasa manufacturing label woven, hangtag dan printing",
        aboutUsContent2:
          "Mina Label adalah perusahaan pembuat label woven untuk garment yang berlokasi di Jakarta, Indonesia",
        aboutUsContent3:
          "Berbagai jenis alat dan mesin produksi dengan sistem komputerisasi yang dimiliki, kami selalu mengutamakan kualitas tanpa kompromi. Dengan memiliki mesin Muller dari Swiss, akan membantu efektivitas dan mempercepat kerja kami dalam menghasilkan produk namun dengan kualitas yang tinggi",
        aboutUsContent4:
          "Menggunakan material terbaik dan sudah melalui tahap seleksi, serta sumber daya manusia yang berpengalaman, menjadikan Mina Label selalu menjadi yang pertama dalam memberikan kualitas pelayanan terbaik untuk seluruh pelanggan di Indonesia",
        aboutUsContent5:
          "Sampai saat ini sudah lebih dari 100.000 brand besar dan ternama yang bekerjasama dengan kami untuk memproduksi label pakaian mereka bersama kami",
        aboutUsContent6:
          "Pelayanan terbaik demi kepuasaan pelanggan, selalu menjadi prioritas utama kami. Dengan visi menjadi salah satu perusahaan label terbaik dan tercepat di Indonesia, kami hadir sebagai solusi untuk memenuhi kebutuhan branding produk anda",
        aboutUsChooseUs1:
          "Kami menyediakan produksi label tercepat dibandingkan dengan kompetitor lainnya",
        aboutUsChooseUs2:
          "( Kami bisa Melakukannya dalam waktu hanya 3 hingga 4 hari. )",
        aboutUsDetail1:
          "Meningkatkan efisiensi tenaga kerja Anda, dengan menggunakan peralatan kami",
        aboutUsDetail2:
          "Kami selalu memberikan pelayanan yang terbaik dan penjelasan yang akurat kepada customer kami",
        aboutUsDetail3:
          "Kami mempunyai designer dengan pengalaman yang tinggi, dan dapat menghasilkan design sesuai dengan permintaan anda",
        aboutUsDetail4:
          "MINA LABEL menyediakan kualitas-kualitas terbaik sesuai dengan kebutuhan brand anda",
        aboutUsContactUs:
          "Hubungi kami untuk mengatur konsultasi dan daftar harga Anda",
        contactUs:
          "Kami menyediakan produk pelabelan industri terbaik di pasaran dengan kualitas terbaik",
        servicesHeader:
          "Kami memiliki bermacam macam produk untuk memenuhi kebutuhan branding pada produk anda dan kami mampu menyesuaikan budget anda",
        servicesHotCut:
          "Potongan manual yang memotong garis lurus / pemotongan simetris",
        servicesLaserCut:
          "Pemotongan dengan mesin yang dikerjakan oleh SDM yang berpengalaman untuk memotong bentuk bentuk yang sulit  ",
        servicesDesign:
          "Kami menggunakan designer yang berpengalaman, siap memberikan design label yang terbaik dan sesuai dengan keinginan anda",
        servicesUltraSonic:
          "Metode finishing yang menciptakan tepian label woven dengan hasil halus, berkualitas tinggi, dan cocok untuk branding premium. Dengan teknologi ini memberikan kesan mewah serta profesional pada hasil Label dan sesuai dengan kebutuhan branding yang tinggi dan meningkatkan nilai produk",
        HangTagDescription:
          "Kami menyediakan produk pelabelan industri terbaik di pasar dengan kualitas terbaik.",
        LabelPrintingDescription:
          "Kami menyediakan produk pelabelan industri terbaik di pasar dengan kualitas terbaik",
        ProductsHeaderDescriptions:
          "Kami memiliki bermacam macam produk untuk memenuhi kebutuhan branding pada produk anda dan kami mampu menyesuaikan budget anda.",
        faqHeader:
          "kumpulan pertanyaan yang sering diajukan tentang topik frekuensi oleh para pengunjung kami",

        products: {
          BlokHalusDescription:
            "Rajutan Benang yang paling padat pada label woven.",
          BlokKasarDescription:
            "setengah rajutan full yang meyerupai block halus tapi dengan pori pori rajutan yang lebih besar dan renggang",
          JalanDasarDescription: "Rajutan benang yang paling renggang",
          LogoDescription: "label woven yang berukuran besar.",
          HangTagDescription:
            "Kami menyediakan produk pelabelan industri terbaik di pasaran dengan kualitas terbaik.",
          LabelPrintingDescription:
            "Kami menyediakan produk pelabelan industri terbaik di pasaran dengan kualitas terbaik.",
          BlokHalus: {
            HeaderDescription1: `"Blok halus" dirancang dengan cermat untuk mencapai tekstur yang padat dan halus, sehingga menghasilkan vibransi warna dan kekayaan yang lebih meningkat. Teknik tenun rumit ini umumnya digunakan dalam produk pakaian mewah di berbagai industri, termasuk pakaian streetwear, pakaian bayi, kemeja, jaket, dan barang-barang yang mencari estetika mewah`,
            HeaderDescription2: `Jenis label tenun ini menampilkan pori-pori rajutan yang lebih besar dan lebih terpisah dibandingkan dengan blok halus standar, menciptakan tekstur yang berbeda yang terasa lebih tipis dan kasar saat disentuh langsung. Meskipun memiliki tekstur seperti ini, label ini tetap mampu menampilkan warna-warna yang cerah secara efektif serta memastikan hasil akhir yang konsisten dan tahan lama. Poros rajutan yang lebih besar memungkinkan sirkulasi udara dan ventilasi yang lebih baik pada pakaian dan aksesoris, menambah kenyamanan dan fungsi produk. Selain itu, jenis label ini umumnya digunakan dalam seragam yang banyak digunakan, jeans, sarung tangan, dan topi`,
            HeaderDescription3: `"Jalan Dasar" mengacu pada jenis label tenun yang menggunakan benang rajut yang paling longgar, menghasilkan tekstur tipis dan tidak berpori tanpa titik rajut yang terlihat. Karena struktur rajutnya yang longgar, label rajut dasar dianggap sebagai salah satu pilihan yang paling terjangkau dalam hal harga. Meskipun label rajut dasar menawarkan solusi yang hemat biaya, penting untuk mempertimbangkan keterbatasannya dalam tampilan warna. Hanya rentang warna terbatas yang dapat mencapai hasil yang memuaskan pada label rajut dasar, karena struktur rajut yang longgar mungkin tidak dapat menampung pola warna yang rumit atau berwarna-warni. Label standar umumnya digunakan dalam seragam sekolah, kain pembungkus, pakaian pembersih, dan produk-produk yang menerapkan label di bagian luar barang`,
            SubBlokHalusDescription:
              "Block halus adalah rajutan benang yang paling padat pada label woven yang menghasilkan textur yang lebih halus dan tebal, warna yang di hasilkan pun lebih cerah dan lebih padat. Pada umumnya label woven block halus di gunakan untuk produk pakaian distro, pakaian bayi, kemeja, jaket dan produk yang ingin slebih terlihat mewah.",
            SubBlokKasarDescription:
              "Block kasar adalah setengah rajutan full yang meyerupai block halus tapi dengan pori pori rajutan yang lebih besar dan renggang, sebab itu block kasar jika di pegang secara langsung akan terasa lebih tipis dan lebih kasar karena pori pori rajutan nya lebih besar, untuk warna pada block kasar tetap menghasilkan warna yang cukup cerah. Pada umumnya label ini banyak di gunakan di seragam, celana jeans, sarung tangan, topi.",
            SubJalanDasarDescription:
              "Jalan dasar adalah rajutan benang yang paling renggang yang menghasilkan textur yang tipis dan tidak berpori pori dan tidak ada titikrajut untuk harga pada label jalan dasar bisa dibilang termurah. Untuk warna pada jalan dasar tidak semua nya bisa cerah hanya warna terbatas yang bisa menghasilkan warna rajutan yang bagus. Pada umumnya label jalan dasar digunakan untuk seragam sekolah, sarung, kain lap, dan produk yang mengaplikasikan label nya di bagian luar.",
            ExampleTitle: "Contoh Label Woven yang terpasang dalam Produk.",
            ExampleSubTitle:
              "Beberapa Cara Pengaplikasian Label Woven Pada Produk.",
            Labels: {
              SlipLabel: "Center Fold",
              SlipLabel1: "Hang Stitch",
              JahitanKanaKiri: "Jahitan Kanan Kiri",
              JahitanKeliling: "Jahitan Keliling",
              JahitanPerahu: "Jahitan Perahu",
            },
          },
          Logo: {
            SubTitle:
              "Kami menyediakan produk pelabelan industri terbaik di pasaran dengan kualitas terbaik.",
            Description:
              "Logo adalah label woven yang berukuran besar yang digunakan di seragam perusahaan atau baju dinas, maupun seragam sekolah dan juga dapat digunakan sebagai logo Brand Anda pada jacket atau hoodie di fashion, seperti yang ada di kantong baju pada seragam SD, SMP, SMA, juga di pakai di berbagai komunitas. Logo dapat di potong sesuai dengan bentuk yang anda inginkan, kami memotongnya dengan mesin laser agar hasil potongan pada logo bisa presisi dan bersih.",
            ExampleTitle: "Contoh Hangtag yang terpasang pada produk",
          },
          HangTag: {
            SubTitle:
              "Kami menyediakan produk pelabelan industri terbaik di pasaran dengan kualitas terbaik.",
            Description:
              "Hang Tag adalah label kertas atau biasa disebut pricetag hangtag biasa digunakan untuk melambangkan produk baru pada sebuah produk, kami menydiakan hangtag dari berbagai macam kertas (ivori ,artcarton, duplex) dan berbagai macam finishing akhir pada hangtag (embose, spot uv, doff, glossy, duble lem (untuk mempertebal ketebalan hangtag).",
            ExampleTitle: "Contoh Label Woven yang terpasang dalam Produk.",
          },
          LabelPrinting: {
            SubTitle:
              "Kami menyediakan produk pelabelan industri terbaik di pasaran dengan kualitas terbaik.",
            SatinPrintingDescription:
              "adalah bahan jadi yang memiliki tekstur yang lembut serta mengkilap",
            NylonPrintingDescription:
              "adalah bahan jadi yang memiliki tekstur yang lebih kaku di bandingkan bahan satin. Nylon memiliki tekstur seperti kertas HVS dan hasil akhirnya adalah doff",
            HowItsWork: "Cara Kerja",
            HowItsWorkDescription1:
              "Label Satin/Nylon Printing adalah bahan yang diprint di atas mesin menggunakan tinta.",
            HowItsWorkDescription2:
              "Biasanya digunakan untuk detail cara pencucian dan cara merawat produk. Penjahitan satin pada produk biasanya di lidah sepatu, bagian baju pada pinggang bawah, dan sebagainya tergantung anda mau meletakkannya dimana dan fungsinya.",
            ExampleTitle: "Contoh Label Woven yang terpasang dalam Produk.",
          },
        },
        faq: {
          Questions: {
            2: "Q: Berapa banyak warna yang ada pada Woven Label?",
            3: "Q: Bisakah saya menggunakan desain saya sendiri?",
            4: "Q: Apakah Mina Label mengirim ke negara lain?",
            5: "Q: Apakah ini pabrik manufaktur?",
            6: "Q: Apakah Anda memberikan sampel gratis?",
            7: "Q: Bagaimana cara pembayaran?",
            8: "Q: Bagaimana cara menghubungi Mina Label?",
            9: "Q: Apakah Anda menerima reseller atau afiliasi?",
            10: "Q: Apa itu woven label?",
            11: "Q: Berapa lama pengiriman ke negara saya?",
          },
          Answers: {
            2: "A: Maksimal warna yang tersedia adalah 7 warna",
            3: "A: Ya, Anda dapat menggunakan desain sendiri",
            4: "A: Ya, Mina Label mengirim ke negara lain",
            5: "A: Ya, kami merupakan pabrik manufaktur untuk Woven Label, Satin, Nylon, Cotton Printing, dan Hangtag",
            6: "A: Ya, kami menyediakan sampel gratis",
            7: "A: Anda dapat melakukan pembayaran melalui transfer bank dan PayPal",
            8: "A: Anda dapat menghubungi Mina Label melalui WhatsApp atau email untuk konsultasi dan pemesanan",
            9: "A: Ya, kami menerima reseller atau afiliasi, silakan hubungi kami untuk informasi lebih lanjut",
            10: "A: Woven label adalah label yang dibuat dengan menggunakan teknik tenun",
            11: "A: Waktu pengiriman tergantung pada ekspedisi atau kurir yang Anda pilih",
          },
        },
        footer1: "Pabrik / Kantor Pemasaran",
        footer2: "Toko 1",
      },
    },
    en: {
      translation: {
        waNumber: "+62 8218-8883-321",
        email: "minalabelsales@gmail.com",
        stickyWa: "Hello Minalabel, I want to Consult",
        welcome: "Welcome to React",
        animationText: "Woven Label only requires 3-4 day for completion",
        aboutUs:
          "We have been in the label industry for almost 39 years, equipped with specialized and modern machines operated by skilled workers with extensive experience. Our company not only produces labels but also manufactures hangtags (paper labels) and printings (washing/care labels) made of satin (taffeta & nylon) materials",
        canvasModelHeader: "Product Quality Comparison",
        canvasModelDetail:
          "We have a wide range of products to meet your branding needs, and we are capable of accommodating your budget as well",
        canvasModelBlockHalus: "Premium",
        canvasModelSubDetail: "Feel free to rotate or zoom the image below",

        canvasModelBlockHalusDesc:
          "The tightest knitting of threads on woven labels",
        canvasModelBlockKasar: "High Quality",
        canvasModelBlockKasarDesc: "Half knit full resembling Premium Quality",
        canvasModelBlockJalan: "Standard",
        canvasModelBlockJalanDesc: "The loosest knit yarn",
        perbandinganKualitasHeader:
          "Quality Comparison Details: Premium, High, and Standard",
        aboutUsHeader:
          "We provide the very best industrial labeling products in the market with the finest quality",
        aboutUsContent1:
          "PT. Palapa Cipta Mega Pesona is a company engaged in manufacturing branding services for garments since 1983. We also have a subsidiary called Mina Label, which specializes in manufacturing woven labels, hangtags, and printing services",
        aboutUsContent2:
          "Mina Label is a Jakarta-based company that produces woven labels for garments",
        aboutUsContent3:
          "With a range of computerized production tools and machines, we prioritize uncompromising quality. Our Swiss-made Muller machines enhance efficiency and speed in delivering high-quality products",
        aboutUsContent4:
          "By utilizing carefully selected, top-quality materials and experienced human resources, Mina Label consistently provides the best service quality to our customers throughout Indonesia",
        aboutUsContent5:
          "To this date, we have collaborated with over 100,000 prominent and renowned brands to produce their garment labels",
        aboutUsContent6:
          "Customer satisfaction is our top priority, and we strive to provide the best service. With a vision to become one of the best and fastest label companies in Indonesia, we are here to fulfill your branding needs",
        aboutUsChooseUs1:
          "WE PROVIDE THE FASTEST LABEL PRODUCTION WITHIN OTHER COMPETITORS",
        aboutUsChooseUs2: "( We can make it happen in only 3 to 4 days )",
        aboutUsDetail1:
          "MINA LABEL provides the best quality according to your brand's needs",
        aboutUsDetail2:
          "MINA LABEL offers the finest qualities that align with your brand's requirements",
        aboutUsDetail3:
          "We have highly experienced designers who can create designs according to your specific requests",
        aboutUsDetail4:
          "We always provide the best service and accurate explanations to our customers",
        aboutUsContactUs:
          "Contact us to arrange your Consultation and Price List",
        contactUs:
          "We provide the very best industrial labeling products in the market with the finest quality",
        servicesHeader:
          "We have a variety of products to meet your branding needs for your products, and we can customize them according to your budget",
        servicesHotCut:
          "Manual cutting tool for straight line cutting / symmetrical cutting",
        servicesLaserCut:
          "Cutting with a machine performed by experienced personnel to cut complex shapes",
        servicesDesign:
          "We also utilize experienced designers who are prepared to deliver the best and personalized label designs according to your preferences",
        servicesUltraSonic:
          "The finishing method creates smooth and high-quality woven label edges that are suitable for premium branding. With this technology, it provides a luxurious and professional impression on the label's outcome, aligning with high branding requirements and enhancing product value",
        HangTagDescription:
          "We provide the very best industrial labeling products in the market with the finest quality",
        ProductsHeaderDescriptions:
          "We have various products to meet your branding needs for your products, and we are able to adjust to your budget.",
        faqHeader:
          "compilation of questions commonly asked by our visitors regarding the topic of frequency",
        products: {
          BlokHalusDescription:
            "The tightly woven thread used for the woven label.",
          BlokKasarDescription:
            "Half-knitted, resembling a smooth block, but with larger and more spaced-out knitting pores.",
          JalanDasarDescription: "The loosest knit thread.",
          LogoDescription: "Large-sized woven label.",
          HangTagDescription:
            "We provide the very best industrial labeling products in the market with the finest quality.",
          LabelPrintingDescription:
            "We provide the very best industrial labeling products in the market with the finest quality.",
          BlokHalus: {
            HeaderDescription1: `Smooth block is carefully crafted to achieve a dense and refined texture, leading to enhanced color vibrancy and richness. This intricate weaving technique is commonly utilized in premium clothing products across various industries, including streetwear, baby clothes, shirts, jackets, and items that seek luxurious aesthetics`,
            HeaderDescription2: `This type of woven label exhibits larger and more spaced-out knitting pores compared to the standard smooth block, creating a distinct texture that feels thinner and rougher when touched directly. Despite this texture, the label still maintains the ability to display vibrant colors effectively as well as ensuring a consistent and durable finish. The larger knitting pores allows better breathability and ventilation in garments and accessories, adding comfort and functionality of the products. Moreover, this type of label are commonly utilized in widely used uniforms, jeans, gloves and hats`,
            HeaderDescription3: `The "base knit" label refers to a type of woven label that utilizes the loosest knit thread, resulting in a thin and non-porous texture without visible knit points. Due to its looser knit structure, the base knit label is considered one of the most affordable options in terms of pricing. While the base knit label offers cost-effective solution, it is essential to consider its limitations in color display. Only a limited range of colors can achieve a satisfactory results on the base knit label as the loose knit structure may not accommodate intricate or vibrant color patterns. The standard label are commonly utilized in school uniforms, wraps, cleaning clothes, and products that apply the label on the exterior parts of the items`,
            SubBlokHalusDescription:
              "Smooth block is the tightly woven thread on woven labels that creates a smoother and thicker texture, resulting in brighter and denser colors. Generally, smooth block woven labels are used for clothing products in streetwear, baby clothes, shirts, jackets, and products that aim for a more luxurious appearance.",
            SubBlokKasarDescription:
              "A half-knitted, full-like block that resembles a smooth block but with larger and more spaced-out knitting pores. As a result, when directly touched, the rough block feels thinner and rougher due to its larger knitting pores. However, it still produces sufficiently bright colors. Generally, this type of label is widely used in uniforms, jeans, gloves, and hats.",
            SubJalanDasarDescription:
              "Base knit is the loosest knit thread that creates a thin and non-porous texture, without visible knit points. In terms of pricing, base knit labels can be considered the most affordable. Not all colors can appear bright on base knit labels; only limited colors can achieve a good knitted color. Generally, base knit labels are used for school uniforms, wraps, cleaning cloths, and products that apply the label on the outer part.",
            ExampleTitle:
              "Here are some examples of woven labels attached to products.",
            ExampleSubTitle:
              "Various Ways of Applying Woven Labels on Products.",
            Labels: {
              SlipLabel: "Center Fold",
              SlipLabel1: "Hang Stitch",
              JahitanKananKiri: "Right-Left Stitching",
              JahitanKeliling: "Edge Stitching",
              JahitanPerahu: "Boat Stitching",
            },
          },
          Logo: {
            SubTitle:
              "We provide the very best industrial labeling products in the market with the finest quality",
            Description:
              "A logo is a large-sized woven label used on corporate uniforms, workwear, and school uniforms. It can also be used as your brand logo on jackets or hoodies in the fashion industry, such as the ones found on the pockets of elementary, middle, and high school uniforms. Logos are also commonly used by various communities. The logo can be cut into any desired shape, and we use laser-cutting machines to ensure precise and clean results.",
            ExampleTitle: "Examples of Hangtags Attached to Products",
          },
          HangTag: {
            SubTitle:
              "We provide the very best industrial labeling products in the market with the finest quality.",
            Description:
              "Hang tags are paper labels, commonly known as price tags or product tags, used to indicate new products on a product. We provide hang tags made from various types of paper, such as ivory, art carton, and duplex. Additionally, we offer a variety of finishing options for hang tags, including embossing, spot UV, matte (doff), glossy, and double-sided adhesive (to increase thickness of the hang tag).",
            ExampleTitle: "Examples of Hangtags Attached to Products",
          },
          LabelPrinting: {
            SubTitle:
              "We provide the very best industrial labeling products in the market with the finest quality.",
            SatinPrintingDescription:
              "It is a finished material that has a smooth and glossy texture",
            NylonPrintingDescription:
              "It is a finished material that has a stiffer texture compared to satin. Nylon has a texture similar to HVS paper, and the end result has a matte finish",
            HowItsWork: "How It's Work",
            HowItsWorkDescription1: `"Label Satin/Nylon Printing" is a material that is printed on a machine using ink.`,
            HowItsWorkDescription2:
              "It is commonly used for providing details about washing instructions and product care. Satin stitching is typically applied on products such as shoe tongues, lower waistbands of clothing, and other areas depending on where you choose to place it and its intended function.",
            ExampleTitle: "Examples of Hangtags Attached to Products",
          },
        },
        faq: {
          Questions: {
            2: "Q: How many color on Woven Label?",
            3: "Q: Can I Use My Design?",
            4: "Q: Do Mina Label ship to another country?",
            5: "Q: Is This Manufacture Factory?",
            6: "Q: Do You Give a Free Sample?",
            7: "Q: How Do I Pay?",
            8: "Q: How do I Contact Mina Label?",
            9: "Q: Do you accept a reseller or affiliate?",
            10: "Q: What is woven label",
            11: "Q: How long does it shipped to my country?",
          },
          Answers: {
            2: "A: Max colour is 7 colours",
            3: "A: Yes, you can Customize your own design",
            4: "A: Yes, We do ship to Worldwide",
            5: "A: Yes we are the manufacture factory for Woven Label, Satin, Nylon, Cotton Printing and Hangtag",
            6: "A: Yes , we are providing a Free Sample of our materials quality",
            7: "A: You can pay by bank transfer and paypal",
            8: "A: From whatsapp or by email for consultation and order",
            9: "A: Yes, please contact us for more information",
            10: "A: You can visit our website on product tab",
            11: "A: It’s depends on the expedition or courier that you choose",
          },
        },
        footer1: "Factory/Marketing Office",
        footer2: "Store 1",
      },
    },
  },
});

export default i18n;
