import React from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from "@material-tailwind/react";
import {
  CubeTransparentIcon,
  UserCircleIcon,
  CodeBracketSquareIcon,
  Square3Stack3DIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  InboxArrowDownIcon,
  LifebuoyIcon,
  PowerIcon,
  RocketLaunchIcon,
  Bars2Icon,
} from "@heroicons/react/24/outline";
import Logo from "./logo.png";
import { useNavigate, useLocation } from "react-router-dom";

const profileMenuItems = [
  {
    label: "My Profile",
    icon: UserCircleIcon,
  },
  {
    label: "Edit Profile",
    icon: Cog6ToothIcon,
  },
  {
    label: "Inbox",
    icon: InboxArrowDownIcon,
  },
  {
    label: "Help",
    icon: LifebuoyIcon,
  },
  {
    label: "Sign Out",
    icon: PowerIcon,
  },
];

function ProfileMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto text-black"
        >
          English (US)
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${
              isMenuOpen ? "rotate-180" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1 pt-7">
        {profileMenuItems.map(({ label, icon }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              key={label}
              onClick={closeMenu}
              className={`flex items-center gap-2 rounded ${
                isLastItem
                  ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                  : ""
              }`}
            >
              {React.createElement(icon, {
                className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color={isLastItem ? "red" : "inherit"}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

export default function Example() {
  const [openNav, setOpenNav] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const changePage = (toPage: string) => {
    let page: string = location.pathname;

    if (page.includes("en")) {
      if (toPage === "aboutus") {
        navigate("/about-us" + page.slice(-3));
      } else if (toPage === "home") {
        navigate(page.slice(-3));
      } else if (toPage === "services") {
        navigate("/services" + page.slice(-3));
      } else if (toPage === "products") {
        navigate("/products" + page.slice(-3));
      } else if (toPage === "contactUs") {
        navigate("/contact-us" + page.slice(-3));
      } else if (toPage === "faq") {
        navigate("/faq" + page.slice(-3));
      } else if (toPage === "design") {
        navigate("/design" + page.slice(-3));
      }
    } else {
      if (toPage === "aboutus") {
        navigate("/about-us" + "/id");
      } else if (toPage === "home") {
        navigate("/id");
      } else if (toPage === "services") {
        navigate("/services" + "/id");
      } else if (toPage === "products") {
        navigate("/products" + "/id");
      } else if (toPage === "contactUs") {
        navigate("/contact-us" + "/id");
      } else if (toPage === "faq") {
        navigate("/faq" + "/id");
      } else if (toPage === "design") {
        navigate("/design" + "/id");
      }
    }
    // console.log(page.slice(-3));
  };

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="
        p-1 
        font-normal 
        underlinehover"
      >
        <a
          onClick={() => changePage("home")}
          className="cursor-pointer flex items-center text-[#083e10]"
        >
          Home
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal underlinehover"
      >
        <a
          onClick={() => changePage("aboutus")}
          className="flex items-center text-[#083e10] cursor-pointer"
        >
          About us
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal underlinehover"
      >
        <a
          onClick={() => changePage("products")}
          className="cursor-pointer flex items-center text-[#083e10]"
        >
          Products
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal underlinehover"
      >
        <a
          onClick={() => changePage("services")}
          className="cursor-pointer flex items-center text-[#083e10]"
        >
          Services
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal underlinehover"
      >
        <a
          onClick={() => changePage("contactUs")}
          className="cursor-pointer flex items-center text-[#083e10]"
        >
          Contact Us
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal underlinehover"
      >
        <a
          onClick={() => changePage("faq")}
          className="cursor-pointer flex items-center text-[#083e10]"
        >
          FAQ
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal underlinehover"
      >
        <a
          onClick={() => changePage("design")}
          className="cursor-pointer flex items-center text-[#083e10]"
        >
          Live Design
        </a>
      </Typography>
    </ul>
  );

  return (
    <>
      <Navbar className="sticky inset-0 z-10 block h-max w-full max-w-full rounded-none border border-white/80 bg-white bg-opacity-80 py-2 px-4 shadow-md backdrop-blur-2xl backdrop-saturate-200 lg:px-8 lg:py-4 ">
        <div className="flex items-center justify-between text-blue-gray-900">
          <Typography
            onClick={() => changePage("home")}
            className="mr-4 cursor-pointer py-1.5 font-medium"
          >
            <img src={Logo} className="w-50 h-7" alt="logo" />
          </Typography>
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">{navList}</div>
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent text-black focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <MobileNav open={openNav}>{navList}</MobileNav>
      </Navbar>
      <div className="h-3"></div>
    </>
  );
}
