import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bca from "./bcafooter.png";
import paypal from "./paypal.png";
import visamaster from "./visa-mastercard.jpg";
const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [hideIdContent, setHideIdContent] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname.slice(-3) === "/en") {
      setHideIdContent(true);
    } else {
      setHideIdContent(false);
    }
  }, [location.pathname]);

  const changeLanguage = (lang: string) => {
    let page: string = location.pathname;

    if (lang === "en") {
      navigate(page.slice(0, -3) + "/en");
      handleTrans("en");
    } else {
      navigate(page.slice(0, -3) + "/id");
      handleTrans("id");
    }
    // navigate("/session-timed-out");
  };

  const handleTrans = (code: string) => {
    i18n.changeLanguage(code);
  };
  return (
    <div className="h-auto md:px-14 px-2 my-6 overflow-hidden flex flex-col gap-4">
      {/* Row pertama */}
      <div className="grid md:grid-cols-4 grid-cols-1">
        <div className="hidden md:block">
          <h1 className="text-lg font-bold mb-1">Services</h1>
          <h1>Laser Cutting</h1>
          <h1>Hot Cut</h1>
          <h1>Design</h1>
        </div>
        <div className="hidden md:block">
          <h1 className="text-lg font-bold mb-1">Products</h1>
          <h1>Label Woven</h1>
          <h1>Label Satin & Nylon Printing</h1>
          <h1>Hang Tag</h1>
          <h1>Logo</h1>
        </div>
        <div className="">
          <h1 className="lg:text-lg font-bold mb-1">Get in touch</h1>
          {hideIdContent ? (
            <h1 className="text-sm lg:text-base">+62 8218-8883-321</h1>
          ) : (
            <h1 className="text-sm lg:text-base">+62 8777-5669-668</h1>
          )}
          {hideIdContent ? (
            <h1 className="text-sm lg:text-base">minalabelsales@gmail.com</h1>
          ) : (
            <h1 className="text-sm lg:text-base">minalabel88@gmail.com</h1>
          )}
        </div>
        <div className="md:flex gap-4 flex-col hidden">
          {/* Pabrik */}
          <div>
            <h1 className="lg:text-lg font-bold mb-1">{t("footer1")}</h1>
            <p className="text-sm lg:text-base">
              Jl. Jembatan Besi 6 No. 7 RT/RW 01/03<br></br>
              Kecamatan Tambora, Jakarta Barat
            </p>
          </div>
          <div>
            <h1 className="lg:text-lg font-bold mb-1"> {t("footer2")}</h1>
            <h1 className="text-sm lg:text-base">
              Tanah Abang Blok Auri Ruko CC3<br></br>
              Mina Label Jakarta Pusat<br></br>
              (Disamping Bank BPR)
            </h1>
          </div>
        </div>
      </div>
      {/* Row Kedua */}
      <div className="flex justify-between ">
        <div className="flex justify-center items-center md:text-base text-xs gap-5 md:flex-row flex-col">
          <h1 className="lg:text-lg font-bold mb-1">Payment Method : </h1>
          <img src={bca} alt="" className="max-h-7 max-w-48" />
          <img src={paypal} alt="" className="max-h-7 max-w-48" />
          <img src={visamaster} alt="" className="max-h-7 max-w-48" />
        </div>
      </div>
      {/* Row Ketiga */}
      <div className="flex justify-between border-t-2 border-t-gray-500">
        <div className="flex justify-center items-center md:text-base text-xs">
          Copyright © 2023 Mina Label Inc. All rights reserved
        </div>
        <div className="flex md:flex-row flex-col justify-center items-center gap-2 md:text-base text-xs w-[40%]">
          <h1 className="mr-1">Language : </h1>
          <a
            onClick={() => {
              changeLanguage("id");
              window.scrollTo({
                top: 0,
                behavior: "smooth", // Add smooth scrolling behavior
              });
            }}
            className="cursor-pointer flex items-center text-black"
          >
            Indonesia (ID)
          </a>
          <a
            onClick={() => {
              changeLanguage("en");
              window.scrollTo({
                top: 0,
                behavior: "smooth", // Add smooth scrolling behavior
              });
            }}
            className="cursor-pointer flex items-center text-black"
          >
            English (EN)
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
