import React from "react";
import battleCard from "./Media/battleCard.png";
import battleCardEng from "./Media/battleCardEng.png";
interface Props {
  hideIdContent: boolean;
}

const BattleCard = ({ hideIdContent }: Props) => {
  return (
    <div className="flex bg-[#2C4854] w-full items-center justify-center py-4">
      {hideIdContent ? (
        <img src={battleCard} alt="" className="" />
      ) : (
        <img src={battleCardEng} alt="" className="" />
      )}
    </div>
  );
};

export default BattleCard;
