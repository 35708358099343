import React from "react";
import ReactPlayer from "react-player/youtube";
const VideoPlayer = () => {
  return (
    <div className="w-full relative bg-warnaUtama  aspect-video">
      <ReactPlayer
        url={"https://youtu.be/XOcz9zUrLm4"}
        width="100%"
        height={"100%"}
      />
    </div>
  );
};

export default VideoPlayer;
